<template>
  <div class="mt-down-header">
    <div class="payPage">
      <div class="newcard">
        <span>保存二维码> 支付宝【扫一扫】></span>
        <span>从相册打开</span>
        <span>保存不成功时,请截屏保存二维码</span>
        <img src="@/assets/img/pay.png" alt="">
        <p style="color: grey;">正式版为<strong style="color:red">8800元/年</strong> </p>
        <p>支付成功后,请联系客服开通正式版</p>
        <el-button type="danger" @click="dialogVisible = true">一键求助小刀客服</el-button>
      </div>
    </div>
    <el-dialog
      title="客服小柒【微信扫一扫】"
      :visible.sync="dialogVisible"
      width="20%"
    >
      <span><img class="chat" src="@/assets/img/chat.jpg" alt=""></span>
      <div style="color:black;font-size:16px;margin-top:10px">客服工作时间为周一到周五,早上九点到下午六点</div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '../../assets/style/common.scss'

export default {
  data() {
    return {
      dialogVisible: false
    }
  },

  mounted() {

  },

  methods: {
    // handleClose(done) {
    //   this.$confirm('确认关闭？')
    //     .then(_ => {
    //       done()
    //     })
    //     .catch(_ => {})
    // }
  }

}
</script>

<style lang="scss" scoped>
.layout-main{
  min-height: 0 !important;
}
.mt-down-header{
  margin-top: 100px;
}
.payPage{
  width: 100%;
  height: 1080px;
  // background: rgba(0,0,0,0.618);
  display: flex;
  // align-items: center;
  justify-content: center;
  .newcard{
      box-shadow: 0 0 10px 5px #989494;
       width: 23%;
       height: 60%;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       img{
         width: 90%;
       }
       span{
         font-size: 18px;
       }
       p{
         margin-bottom: 0.3rem;
         font-size: 18px;
       }
  }
}
.chat{
  // width: 300px;
  // height: 300px;
  width: 88%;
  height: 88%;
}
</style>
